import {digg} from "diggerize"
import qs from "qs"
import RailsVariables from "./rails-variables.js.erb"
import SourceMapsLoader from "@kaspernj/api-maker/src/source-maps-loader"

export default class BugReporting {
  constructor(data) {
    this.authToken = data.authToken
    this.connect()
    this.testing = false
    this.collectEnvironmentCallback = undefined
    this.collectParamsCallback = undefined
    this.sourceMapsLoader = new SourceMapsLoader()
    this.sourceMapsLoader.loadSourceMapsForScriptTags((script) => {
      const src = script.getAttribute("src")
      const type = script.getAttribute("type")

      if (this.loadSourceMapForScriptTagsCallback && src && (type == "text/javascript" || !type)) {
        Peakflow.debugger.debug(`Loading source map for ${src}`)
        const result = this.loadSourceMapForScriptTagsCallback(script)

        if (result) {
          return result
        }
      }
    })
  }

  collectEnvironment(callback) {
    this.collectEnvironmentCallback = callback
  }

  collectParams(callback) {
    this.collectParamsCallback = callback
  }

  connect() {
    Peakflow.debugger.debug("Connecting handler")
    this.isHandlingError = false

    this.connectOnError()
    this.connectUnhandledRejection()
  }

  connectOnError() {
    window.addEventListener("error", async (event) => {
      Peakflow.debugger.debug(`Error cought with message: ${event.message}`)
      Peakflow.debugger.debug(`Message: ${event.message}`)
      Peakflow.debugger.debug(`File: ${event.filename}`)
      Peakflow.debugger.debug(`Line: ${event.lineno}`)
      Peakflow.debugger.debug(`Error: ${event.error}`)

      if (!this.isHandlingError) {
        this.isHandlingError = true
        await this.handleError({
          error: event.error,
          errorClass: event.error?.name,
          file: event.filename,
          line: event.lineno,
          message: event.message || "Unknown error",
          url: window.location.href
        }).finally(() => {
          this.isHandlingError = false
        })
      }
    })
  }

  connectUnhandledRejection() {
    window.addEventListener("unhandledrejection", async (event) => {
      Peakflow.debugger.debug(`Unhandled rejection: ${JSON.stringify(event.reason.message)}`)

      if (!this.isHandlingError) {
        this.isHandlingError = true
        await this.handleError({
          error: event.reason,
          errorClass: "UnhandledRejection",
          file: null,
          line: null,
          message: event.reason.message || "Unhandled promise rejection",
          url: window.location.href
        }).finally(() => {
          this.isHandlingError = false
        })
      }
    })
  }

  async handleError(data) {
    Peakflow.debugger.debug(`Handle error: ${JSON.stringify(data)}`)

    let backtrace, postUrl

    if (data.error && data.error.stack) {
      Peakflow.debugger.debug("Parse stacktrace")
      await this.sourceMapsLoader.loadSourceMaps(data.error)
      backtrace = this.sourceMapsLoader.parseStackTrace(data.error.stack)
    } else if (data.file) {
      Peakflow.debugger.debug("No stacktrace was present on the error so cant parse it")
      backtrace = [`${data.file}:${data.line}`]
    }

    Peakflow.debugger.debug(`AuthToken: ${this.authToken}`)
    Peakflow.debugger.debug(`Backtrace: ${backtrace}`)

    const errorClass = data.errorClass || "UnknownError"
    const postData = {
      auth_token: this.authToken,
      error: {
        backtrace,
        error_class: errorClass,
        message: data.message,
        url: data.url,
        user_agent: navigator ? navigator.userAgent : null
      }
    }

    if (this.testing) {
      postUrl = RailsVariables.bugReportsPath
    } else {
      postUrl = RailsVariables.bugReportsUrl
    }

    Peakflow.debugger.debug(`Sending error report to: ${postUrl}`)

    if (data.error?.peakflowParameters) {
      postData.error.parameters = data.error.peakflowParameters
    } else {
      Peakflow.debugger.debug("Resolving params")
      postData.error.parameters = await this.resolveParams(data)
      Peakflow.debugger.debug("Resolved params", postData.error.parameters)
    }

    if (data.error?.peakflowEnvironment) {
      postData.error.environment = data.error.peakflowEnvironment
    } else {
      Peakflow.debugger.debug("Resolving environment")
      postData.error.environment = await this.resolveEnvironment(data)
      Peakflow.debugger.debug("Resolved environment", postData.error.environment)
    }

    const xhr = new XMLHttpRequest()

    xhr.open("POST", postUrl, true)
    xhr.setRequestHeader("Content-Type", "application/json")

    await this.loadXhr(xhr, JSON.stringify(postData))

    Peakflow.debugger.debug(`Data received: ${xhr.responseText}`)

    const response = JSON.parse(digg(xhr, "responseText"))

    // If the account has run out of bug reports, then this can potentially crash because 'url' wont be present.
    if (response.url) {
      console.log(`Peakflow: Error was reported: ${digg(response, "url")}`)
    }
  }

  loadSourceMapForScriptTags(callback) {
    this.loadSourceMapForScriptTagsCallback = callback
  }

  loadUrl(url) {
    const parser = document.createElement("a")

    parser.href = url

    return parser
  }

  loadXhr(xhr, postData) {
    return new Promise((resolve) => {
      xhr.onload = () => resolve()
      xhr.send(postData)
    })
  }

  async resolveEnvironment(args) {
    if (this.collectEnvironmentCallback) {
      return await this.collectEnvironmentCallback(args)
    }
  }

  async resolveParams(args) {
    if (this.collectParamsCallback) {
      return await this.collectParamsCallback(args)
    } else if (typeof location == "object") {
      return qs.parse(location.search.substr(1))
    }
  }

  setTesting(value) {
    this.testing = value
  }
}
