import BugReporting from "api/bug-reporting"
import Debugger from "api/debugger"

const result = {
  debugger: new Debugger(),
  BugReporting,
  ErrorReporting: BugReporting
}

window.PeakFlow = result
window.Peakflow = result
