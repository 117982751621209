export default class Debugger {
  constructor() {
    this.debugging = false
  }

  debug(...messages) {
    if (this.debugging) {
      console.log("Peakflow JS errors:", ...messages)
    }
  }

  setDebugging(value) {
    this.debugging = value
  }
}
